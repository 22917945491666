import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const StorybookEmbed = makeShortcode("StorybookEmbed");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Base`}</h2>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook" stories={[{
      id: 'size-base--base'
    }]} height="1240" mdxType="StorybookEmbed" />
    <h2>{`Border`}</h2>
    <h3>{`Border size`}</h3>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook" stories={[{
      id: 'size-functional-border--border-size'
    }]} height="500" mdxType="StorybookEmbed" />
    <h3>{`Border radius`}</h3>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook" stories={[{
      id: 'size-functional-border--border-radius'
    }]} height="380" mdxType="StorybookEmbed" />
    <h3>{`Outline`}</h3>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook" stories={[{
      id: 'size-functional-border--outline'
    }]} height="240" mdxType="StorybookEmbed" />
    <h2>{`Breakpoints`}</h2>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook" stories={[{
      id: 'size-functional-breakpoints--breakpoints'
    }]} height="340" mdxType="StorybookEmbed" />
    <h2>{`Viewport`}</h2>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook" stories={[{
      id: 'size-functional-viewport--viewport'
    }]} height="360" mdxType="StorybookEmbed" />
    <h2>{`Layout`}</h2>
    <h3>{`Stack`}</h3>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook" stories={[{
      id: 'size-functional-stack--stack'
    }]} height="970" mdxType="StorybookEmbed" />
    <h3>{`Controls`}</h3>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook" stories={[{
      id: 'size-functional-control--x-small'
    }, {
      id: 'size-functional-control--small'
    }, {
      id: 'size-functional-control--medium'
    }, {
      id: 'size-functional-control--large'
    }, {
      id: 'size-functional-control--x-large'
    }]} height="440" mdxType="StorybookEmbed" />
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook" stories={[{
      id: 'size-functional-control--control-stack-regular'
    }, {
      id: 'size-functional-control--control-stack-responsive'
    }]} height="440" mdxType="StorybookEmbed" />
    <h3>{`Overlay`}</h3>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook" stories={[{
      id: 'size-functional-overlay--overlay'
    }]} height="740" mdxType="StorybookEmbed" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      